import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import PublicImages from "../../constants/public/images";
import Layout from "../../layouts/auth/Layout";

import { Link, useLocation, useNavigate } from "react-router-dom";

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGoogleLogin } from "@react-oauth/google";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { LoginSocialFacebook } from "reactjs-social-login";
import * as Yup from "yup";
import Loader from "./../../components/Loader";
import { getRequest, postRequestForm } from "./../../helper/api";
import { setItemInLocalStorage } from "./../../helper/helper";

function LoginV2() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [authUrl, setAuthUrl] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const [isShowPassword, setIsShowPassword] = useState(false);

  const loginHandler = async (values) => {
    try {
      setLoading(true);
      const { result, error } = await postRequestForm(`/api/auth/login`, "", {
        email: values?.email?.toLowerCase(),
        password: values?.password,
      });
      if (result?.status === 200 || result?.status === 201) {
        const { user } = result?.data;
        if (user.role === "admin") {
          navigate(`/otp`, { state: { _id: user._id, pathToGo: "dashboard" } });
        } else {
          navigate(`/otp`, { state: { _id: user._id, pathToGo: "account" } });
        }
      } else if (error?.response?.status === 400) {
        setEmail(values.email);
        setPassword(values.password);
        toast.error(error?.response?.data?.message, {
          position: "top-center",
          theme: "colored",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      alert("Please enter correct email or password");
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
    }
  };
  const googleAuth = async (code) => {
    try {
      setLoading(true);
      const { result, error } = await postRequestForm(
        "/api/auth/googleAuth",
        "",
        {
          code,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { user, token } = result?.data;
        setItemInLocalStorage("TOKEN", token);
        setItemInLocalStorage("ROLE", user.role);
        setItemInLocalStorage("USER", JSON.stringify(user, null, 2));

        if (user.role === "admin") {
          window.location.assign("/dashboard");
        } else {
          navigate(`/account`, {
            state: { _id: user._id },
          });
        }
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center",
          theme: "colored",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }

      // if (result?.status === 200 || result?.status === 201) {
      //   const { user } = result?.data;
      //   if (user.role === "admin") {
      //     navigate(`/otp`, { state: { _id: user._id, pathToGo: "dashboard" } });
      //   } else {
      //     navigate(`/otp`, { state: { _id: user._id, pathToGo: "account" } });
      //   }
      // } else if (error?.response?.status === 400) {
      //   setEmail(values.email);
      //   setPassword(values.password);
      //   toast.error(error?.response?.data, {
      //     position: "top-center", theme: "colored",
      //     autoClose: 10000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: false,
      //     draggable: true,
      //     progress: undefined,
      //   });
      // }
    } catch (err) {
      alert("Please enter correct email or password");
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
    }
  };

  // const googleLoginHandler = useGoogleLogin({
  //   onSuccess: async (response) => {
  //     console.log("Authorization Code:", response);
  //     try {
  //       // Send the authorization code to your backend to exchange it for tokens
  //       const res = await fetch("/api/auth/googleAuth", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ code: response.code }),
  //       });
  //       const data = await res.json();
  //       console.log("Backend response:", data);
  //     } catch (error) {
  //       console.error("Error during Google login:", error);
  //     }
  //   },
  //   onError: (error) => {
  //     console.error("Login Failed:", error);
  //   },
  //   flow: "auth-code", // Enable PKCE flow internally
  //   ux_mode: "redirect", // Use redirect to ensure the system browser is used
  //   scope: "openid profile email",
  //   select_account: "prompt",
  //   access_type: "offline",
  //   redirect_uri: window.location.origin + "/login",
  // });

  const facebookAuth = async (response) => {
    try {
      setLoading(true);
      const { result, error } = await postRequestForm(
        "/api/auth/facebookAuth",
        "",
        {
          token: response?.accessToken,
        }
      );
      if (
        result?.status === 200 ||
        result?.status === 201 ||
        result?.status === 304
      ) {
        const { user, token } = result?.data;
        setItemInLocalStorage("TOKEN", token);
        setItemInLocalStorage("ROLE", user.role);
        setItemInLocalStorage("USER", JSON.stringify(user, null, 2));

        if (user.role === "admin") {
          window.location.assign("/dashboard");
        } else {
          navigate(`/account`, {
            state: { _id: user._id },
          });
        }
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center",
          theme: "colored",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      alert("Please enter correct email or password");
      console.log(`Error of login`, err.message);
    } finally {
      setLoading(false);
    }
  };
  const responseFacebook = (response) => {
    console.log(response);
  };

  // Render a custom button that redirects to Google login page
  const handleGoogleLogin = () => {
    if (authUrl) {
      window.location.href = authUrl;
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    if (code) {
      // console.log("code",code)
      googleAuth(code);
    }
  }, [location]);

  useEffect(() => {
    const fetchAuthUrl = async () => {
      try {
        // Assuming `getRequest` already parses JSON
        const { result, error } = await getRequest(`/api/auth/googleAuthURL`);

        if (result && result.url) {
          setAuthUrl(result.url); // Set the authUrl from the result
        } else if (error) {
          console.error("Error fetching Google auth URL:", error.message);
        }
      } catch (error) {
        console.error("Unexpected error fetching Google auth URL:", error);
      }
    };
    fetchAuthUrl();
  }, []);

  return (
    <Layout>
      <section className="login">
        <Container>
          <Row className="bod p-6 align-items-center ">
            <Col lg={6} md={12} xs={12} className="d-none d-lg-block">
              <Image fluid src={PublicImages.SigninImage} alt="" />
            </Col>
            <Col lg={6} md={12} xs={12} className="signin">
              {loading ? (
                <Loader loading={loading} />
              ) : (
                <Formik
                  initialValues={{ email, password }}
                  validationSchema={LoginSchema}
                  onSubmit={loginHandler}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="text-center">
                        <h1 className="Sign">Sign in</h1>
                        <p className="access mb-3">
                          Register once to unlock access to this content and all
                          other resources available on Womanii
                        </p>
                      </div>
                      <InputGroup className="justify-content-center">
                        <Row className="justify-content-center align-items-center mb-3 w-100">
                          <Col
                            xl="6"
                            lg="12"
                            md="6"
                            sm="12"
                            className="mb-3 p-0 pe-md-1 p-lg-0 pe-xl-1"
                          >
                            <div className="faceBookAuth">
                              <LoginSocialFacebook
                                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                onReject={(err) => {
                                  console.log(err);
                                  toast.error("Something went wrong", {
                                    position: "top-center",
                                    theme: "colored",
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: false,
                                    draggable: true,
                                    progress: undefined,
                                  });
                                }}
                                onResolve={({ provider, data }) => {
                                  console.log(provider, data);
                                  facebookAuth(data);
                                }}
                              >
                                <a className="btn w-100" href="#">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 39 39"
                                    fill="none"
                                  >
                                    <circle
                                      cx="19.5"
                                      cy="19.5"
                                      r="19.5"
                                      fill="white"
                                    />
                                    <path
                                      d="M24.557 19.9769H21.2943V32H16.3511V19.9769H14V15.7515H16.3511V13.0172C16.3511 11.0619 17.2744 8 21.3384 8L25 8.01538V12.1169H22.3432C21.9074 12.1169 21.2946 12.3359 21.2946 13.2687V15.7554H24.9889L24.557 19.9769Z"
                                      fill="#507CBE"
                                    />
                                  </svg>
                                  <span className="ps-2">
                                    Sign in with Facebook
                                  </span>
                                </a>
                              </LoginSocialFacebook>
                            </div>
                          </Col>
                          <Col
                            xl="6"
                            lg="12"
                            md="6"
                            sm="12"
                            className="mb-3 googleLoginContainer p-0 ps-md-1 p-lg-0 ps-xl-1"
                          >
                            <div className="googleAuth">
                              <a
                                className="btn w-100"
                                href={authUrl || "#"} // Prevent empty `href` if `authUrl` is null
                                onClick={(e) => {
                                  if (!authUrl) {
                                    e.preventDefault(); // Prevent navigation if `authUrl` is not ready
                                    console.error(
                                      "Google Auth URL not set yet."
                                    );
                                  }
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="27"
                                  height="28"
                                  viewBox="0 0 27 28"
                                  fill="none"
                                >
                                  <g clipPath="url(#clip0_1898_46)">
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M1.48438 7.72272C2.0461 6.46685 2.84022 5.3704 3.772 4.37848C5.87978 2.13445 8.43087 0.697341 11.4512 0.207024C15.6786 -0.47882 19.4619 0.533401 22.7316 3.39409C22.9381 3.57457 22.9892 3.68061 22.765 3.90396C21.6111 5.0523 20.4751 6.21943 19.3324 7.3798C19.2155 7.49862 19.1363 7.64376 18.9283 7.45049C16.0501 4.77029 11.3513 4.80112 8.29247 7.69189C7.24228 8.68456 6.49553 9.8735 6.00707 11.2369C5.93602 11.1895 5.86053 11.1474 5.79318 11.0948C4.35667 9.97202 2.92089 8.84775 1.48512 7.72347L1.48438 7.72272Z"
                                      fill="#D7282A"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M5.97241 16.709C6.38538 17.7738 6.89752 18.7808 7.65982 19.6358C9.59737 21.8107 11.9997 22.7485 14.8772 22.4446C16.2145 22.3033 17.4327 21.843 18.571 21.1361C18.6805 21.2354 18.7841 21.3414 18.8996 21.4332C20.2317 22.4898 21.5661 23.5449 22.899 24.5999C21.4285 26.019 19.6922 26.9635 17.7391 27.4651C13.1343 28.6481 8.88985 27.9006 5.12056 24.8782C3.56267 23.6283 2.33634 22.0852 1.47266 20.2585C2.97208 19.0756 4.47225 17.8919 5.97167 16.709H5.97241Z"
                                      fill="#45AC43"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M22.9007 24.6011C21.567 23.546 20.2334 22.4909 18.9012 21.4343C18.7858 21.3426 18.6821 21.2366 18.5726 21.1373C19.4755 20.4372 20.2289 19.6092 20.7233 18.5571C20.9202 18.1375 21.0593 17.6983 21.1903 17.2546C21.2806 16.9485 21.2525 16.8297 20.8802 16.8327C18.6607 16.8515 16.4411 16.8425 14.2216 16.8417C13.9083 16.8417 13.7514 16.677 13.7509 16.3477C13.7509 14.8188 13.7583 13.2907 13.7443 11.7618C13.7413 11.467 13.7924 11.3535 14.1195 11.355C18.2129 11.367 22.3071 11.3648 26.4006 11.3588C26.6218 11.3588 26.7602 11.3753 26.7987 11.6483C27.3079 15.2866 26.9001 18.7624 25.0255 21.9735C24.4519 22.9564 23.7621 23.8543 22.8992 24.6011H22.9007Z"
                                      fill="#5D7FBE"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M5.97265 16.7093C4.47322 17.8922 2.97306 19.0752 1.47363 20.2588C0.740942 18.8751 0.319091 17.3891 0.121486 15.8407C-0.219696 13.1583 0.15553 10.5773 1.27529 8.11672C1.33672 7.98135 1.41443 7.85426 1.48473 7.72266C2.92051 8.84618 4.35629 9.97045 5.7928 11.0932C5.86015 11.1459 5.9349 11.188 6.00669 11.2353C5.405 13.056 5.43534 14.8811 5.97265 16.7086V16.7093Z"
                                      fill="#F4C300"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1898_46">
                                      <rect
                                        width="27"
                                        height="28"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span className="ps-2">
                                  Sign in with Google
                                </span>
                              </a>
                            </div>
                          </Col>
                        </Row>
                      </InputGroup>
                      <InputGroup className="mb-3" size="lg">
                        <Form.Control
                          type="email"
                          placeholder="Email Address  *"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="email"
                          defaultValue={values.email}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3" size="lg">
                        <Form.Control
                          type={isShowPassword ? "text" : "password"}
                          placeholder="Password"
                          name="password"
                          style={{ borderRight: "none" }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={values.password}
                          className="form-control"
                        />
                        <InputGroup.Text
                          style={{
                            background: "#fff",
                            borderLeft: "none",
                          }}
                          onClick={() => setIsShowPassword(!isShowPassword)}
                        >
                          <FontAwesomeIcon
                            icon={isShowPassword ? faEyeSlash : faEye}
                            size="lg"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      <div id="status">
                        <div
                          className={`alert ${
                            (errors.email && touched.email) ||
                            (errors.password && touched.password)
                              ? ""
                              : "d-none"
                          } ${errors ? "alert-danger" : "alert-success"}`}
                          role="alert"
                        >
                          {errors.email && touched.email
                            ? errors.email
                            : errors.password && touched.password
                            ? errors.password
                            : ""}
                        </div>
                      </div>
                      <InputGroup className="mb-3">
                        <Button type="submit" className="btn-default w-100">
                          Sign in
                        </Button>
                      </InputGroup>
                      <InputGroup className="justify-content-center justify-content-lg-end forgetPassLink mb-3">
                        <Link className="btn  p-0" to="/forget-password">
                          Forgot Password?
                        </Link>
                      </InputGroup>

                      <div className="mt-3 text-center">
                        <p>
                          Do You Want to Create An Account{" "}
                          <Link className="btn p-0" to="/sign-up">
                            <b>Sign Up</b>
                          </Link>
                        </p>
                      </div>
                    </form>
                  )}
                </Formik>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}

export default LoginV2;
