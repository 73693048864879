import { useCallback, useEffect, useState } from "react";
import { Col, Container, Image, Modal, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import PublicImages from "../../constants/public/images";
import { useLocation } from "react-router-dom";
import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from "./../../helper/helper";
import { getRequest } from "../../helper/api";
import Loader from "../Loader";
import CountUp from "react-countup";

export const Footer = ({
  settings,
  languagesettings,
  startUpPopupSettings,
}) => {
  const [languageData, setLanguageData] = useState(null);
  const [startUpData, setStartUpData] = useState(null);
  const [showStartPopup, setshowStartPopup] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [visitors, setVisitors] = useState(() => []);
  const [pending, setPending] = useState(() => false);

  const location = useLocation();
  const isHomepage = location.pathname === "/"; // Update the path to match your homepage path

  const getValueByKey = (options, key) => {
    const option = options?.find((option) => option.key === key);
    return option?.value;
  };
  // Function to handle popup close and save timestamp
  const handlePopupClose = async () => {
    try {
      const currentTime = new Date().getTime();
      await setItemInLocalStorage(
        "StartUpPopupShown",
        JSON.stringify(currentTime)
      );
    } catch (error) {
      console.log(error);
    } finally {
      setshowStartPopup(false);
    }
  };
  // Function to check if popup should be shown
  const handlePopupShow = async (startUpData) => {
    if (startUpData?.status === "active") {
      const storedTimestamp = JSON.parse(
        getItemFromLocalStorage("StartUpPopupShown")
      );
      const currentTime = new Date().getTime();
      const twelveHours = 12 * 60 * 60 * 1000;

      // Show the popup only if 12 hours have passed or no timestamp exists
      if (!storedTimestamp || currentTime - storedTimestamp > twelveHours) {
        if (startUpData.popupCondition === "homePage" && isHomepage) {
          setshowStartPopup(true);
        } else if (startUpData.popupCondition === "allOverSite") {
          setshowStartPopup(true);
        }
      }
    }
  };

  const fetchData = useCallback(async (url, setter) => {
    try {
      setPending(true);
      const response = await getRequest(url);

      if (response.result.status === 200) {
        const { insights } = response.result.data;
        setter(insights);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setPending(false);
    }
  }, []);
  const getCounts = useCallback(() => {
    fetchData("/api/secure/visitors", setVisitors);
  }, [fetchData]);
  useEffect(() => {
    setLanguageData(languagesettings);
    setStartUpData(startUpPopupSettings);
    setTimeout(() => {
      handlePopupShow(startUpPopupSettings);
    }, 5000);
    getCounts();
  }, [languagesettings, startUpPopupSettings]);

  return (
    <>
      <footer id="footer">
        <div className="widgets pb-2 pt-3">
          <Container className="text-center">
            <h1>
              Visitors: <CountUp end={visitors?.count} />
            </h1>
          </Container>
        </div>
        <div className="widgets pt-2">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} md={6} xs={12}>
                <h1>{getValueByKey(languageData, "footer_widget_text_1")}</h1>
                <Nav as="ul">
                  <Nav.Item as="li">
                    <Nav.Link href={settings.fb_link} target="_blank">
                      <i className="fab fa-facebook-f"></i>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link href={settings.tw_link} target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="33"
                        height="32"
                        viewBox="0 0 33 32"
                        fill="none"
                      >
                        <path
                          d="M18.1868 11.571C21.334 8.07506 24.4685 4.78196 27.3702 1.28167C28.5321 -0.120626 29.7533 -0.0377532 31.419 0.0778326C27.3702 4.58786 23.4653 8.93869 19.4948 13.3615C23.9606 19.5202 28.3988 25.6397 33 31.9817C32.2338 31.9817 31.692 31.9817 31.1523 31.9817C28.8263 31.9817 26.4982 31.949 24.1722 31.9992C23.4187 32.0166 22.9595 31.7658 22.515 31.1421C19.9329 27.5153 17.3085 23.9212 14.6947 20.3163C14.5084 20.0589 14.2968 19.819 14.0237 19.4788C13.247 20.3272 12.502 21.1297 11.7718 21.9454C9.1622 24.8503 6.47217 27.6832 3.99166 30.6994C2.85935 32.0777 1.6318 32.0886 0.00634941 31.8945C0.321703 31.515 0.529117 31.2468 0.75558 30.996C4.5356 26.7847 8.30503 22.5625 12.1126 18.3775C12.6353 17.8039 12.6353 17.4441 12.1866 16.8312C8.33889 11.5928 4.52501 6.32601 0.706901 1.06576C0.50372 0.786614 0.321703 0.494378 0 0.0124067C1.08152 0.0124067 1.94504 0.0124067 2.81067 0.0124067C4.85518 0.0124067 6.90181 -0.0312106 8.9442 0.0451197C9.41617 0.0625666 10.0278 0.37443 10.3114 0.758263C12.9443 4.30217 15.5201 7.88969 18.1868 11.571ZM4.3176 2.23907C4.53136 2.60327 4.60544 2.7603 4.70703 2.89769C11.0755 11.6757 17.4439 20.4537 23.8272 29.2207C23.9965 29.4541 24.3182 29.7027 24.5765 29.7136C25.8654 29.7638 27.1585 29.7376 28.5893 29.7376C28.4284 29.4432 28.3628 29.2862 28.2676 29.1509C21.8801 20.3359 15.4947 11.5208 9.09235 2.71886C8.92515 2.48987 8.59075 2.2696 8.32831 2.2587C7.06901 2.21072 5.80759 2.23689 4.3176 2.23689V2.23907Z"
                          fill="#E32975"
                        />
                      </svg>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link href={settings.insta_link} target="_blank">
                      <i className="fab fa-instagram"></i>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link href={settings.yt_link} target="_blank">
                      <i className="fab fa-youtube"></i>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <h1>{getValueByKey(languageData, "footer_widget_text_2")}</h1>
                <Row className="justify-content-center">
                  <Col lg={5} md={6} xs={6}>
                    <a href={settings.appstore_link} target="_blank">
                      <Image fluid src={PublicImages.AppleImage} alt="" />
                    </a>
                  </Col>
                  <Col lg={5} md={6} xs={6}>
                    <a href={settings.playstore_link} target="_blank">
                      <Image fluid src={PublicImages.AndroidImage} alt="" />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="copyright">
          <Container>
            <Row className="align-items-center justify-content-between">
              <Col lg={"auto"} md={12} xs={12}>
                <Nav
                  as="ul"
                  className="justify-content-center justify-content-lg-start "
                >
                  <Nav.Item as="li">
                    <Link className="nav-link" to="/terms-and-conditions">
                      Terms & Conditions
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link className="nav-link" to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link className="nav-link" to="/advertisement">
                      Advertisement
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link className="nav-link" to="/faqs">
                      FAQS
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link className="nav-link" to="/contact-us">
                      Contact Us
                    </Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={"auto"} md={12} xs={12}></Col>
              <Col lg={"auto"} md={12} xs={12}>
                <p className="m-lg-0 mb-2 mt-1 text-center text-lg-end">
                  {settings.copyright}
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
      {showStartPopup && (
        <Modal
          centered
          animation
          show={showStartPopup}
          onHide={handlePopupClose}
          id="menu-modal"
          size="md"
          backdropClassName="welcome-popup-backdrop"
        >
          <Modal.Header
            className="justify-content-center position-relative"
            style={{
              borderRadius: "25px 25px 0px 0px",
              background: "#E5097D",
            }}
          >
            <h5
              className="mb-0"
              style={{
                color: "#FFFFFF",
                textAlign: "center",
                fontFamily: "Editors-Note",
                fontSize: "36px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                textTransform: "uppercase",
              }}
            >
              {startUpData?.name}
            </h5>
            <button
              type="button"
              className="btn-close position-absolute"
              style={{
                right: "15px",
                top: "15px",
                filter: "invert(1)",
                opacity: "0.7",
              }}
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handlePopupClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="startUPpopup">
            <Container fluid className="p-0">
              <div
                style={{
                  fontFamily: "Overpass-Medium",
                  lineHeight: "38.2px",
                }}
                dangerouslySetInnerHTML={{
                  __html: startUpData?.description,
                }}
              ></div>
            </Container>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
