import Quill from "quill";
const Embed = Quill.import("blots/embed");

class CustomMentionBlot extends Embed {
  static blotName = "mention";
  static tagName = "span";
  static className = "mention";

  constructor(scroll, node) {
    super(scroll, node);
    this.mounted = false;
  }

  static create(data) {
    const node = super.create();
    if (!data || !(node instanceof HTMLElement)) {
      return node;
    }

    const link = document.createElement("a");
    link.setAttribute("href", data.denotationChar === "@" 
      ? `https://womanii.com/profile/${data.id}` 
      : `https://womanii.com/trending-posts?tag=${data.denotationChar}${data.value}`);
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener noreferrer");
    link.setAttribute("contentEditable", "true");
    link.className = "mention-link";
    link.innerText = `${data.denotationChar}${data.value}`;

    node.appendChild(link);
    node.contentEditable = "true"; // Make the entire node non-editable

    return CustomMentionBlot.setDataValues(node, data);
  }

  static setDataValues(element, data) {
    Object.keys(data).forEach((key) => {
      element.dataset[key] = data[key];
    });
    return element;
  }

  static value(domNode) {
    return domNode.dataset;
  }
}

Quill.register(CustomMentionBlot);
export default CustomMentionBlot;