import { useEffect, useState } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import { getRequest, postRequestForm } from "../../../../helper/api";
import draftToHtml from "draftjs-to-html";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import InputGroup from "react-bootstrap/InputGroup";
import { AsyncPaginate } from "react-select-async-paginate";
import PostEditor from "../../../../components/admin/PostEditor";
import { getItemFromLocalStorage } from "../../../../helper/helper";
import htmlToDraft from "html-to-draftjs";
import EditorWithMentionsAndHashtags from "../../../../components/Editor/EditorWithMentionsAndHashtags";
import { useCallback } from "react";

const AddShortStoryPost = ({ state }) => {
  const navigate = useNavigate();
  const { product } = state || {};
  const [shortStories, setShortStories] = useState(false);

  const [images, setImages] = useState([]);
  const [featuredFile, setFeaturedFile] = useState([]);
  const [featuredImage, setFeaturedImage] = useState("");
  const [status, setStatus] = useState();
  const [title, setTitle] = useState();
  const [challenge, setChallenge] = useState("");
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [description, setDescription] = useState("");
  const [mentions, setMentions] = useState([]);
  const [hashtags, setHashtags] = useState([]);

  const handleContentChange = (htmlContent) => {
    setDescription(htmlContent);
    const { mentions, hashtags } = extractMentionsAndHashtags(htmlContent);
    setMentions(mentions);
    setHashtags(hashtags);
  };

  const extractMentionsAndHashtags = useCallback((content) => {
    const mentions = [...content.matchAll(/@(\w+)/g)].map((m) => m[1]);
    const hashtags = [...content.matchAll(/#(\w+)/g)].map((h) => h[1]);
    return {
      mentions: [...new Set(mentions)],
      hashtags: [...new Set(hashtags)],
    };
  }, []);
  const [subCategory, setSubCategory] = useState();
  const [category, setCategory] = useState();
  const [linkToChallenge, setLinkToChallenge] = useState(false);

  // Effect to handle initial state setup
  useEffect(() => {
    if (state) {
      setTitle(state.title || "");
      setStatus(
        state.status
          ? {
              value: state.status,
              label: getLabelByValue(statusOptions, state.status),
            }
          : null
      );
      setUser(state.user || null);
      setCategory(state.category || null);
      setSubCategory(state.subCategory || null);

      if (state.article) {
        console.log("working", "state.article", state.article);

        setDescription(state.article);

        setIsReady(true);
      } else {
        setDescription("");

        setIsReady(true);
      }
    }
  }, [state]);

  // Effect to handle navigation based on state
  useEffect(() => {
    if (!state || !state.product) {
      navigate(-1);
    }
  }, [state, navigate]);
  const getWordCount = (editor) => {
    const words = editor?.trim().split(/\s+/);
    return words?.length;
  };
  function converEditorToHtml(editor) {
    const html = draftToHtml(convertToRaw(editor.getCurrentContent()));
    return html;
  }
  const fetchUsers = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/user/get-all`,
        token,
        {
          page: page,
          search: search,
        }
      );

      // Check status code
      if (
        result?.status === 200 ||
        result?.status === 201 ||
        result?.status === 304
      ) {
        const currentPage = result?.data?.currentPage || 1;
        const totalPages = result?.data?.totalPages || 1;
        const users = result?.data?.users || [];

        return {
          options: users,
          hasMore: currentPage < totalPages,
          additional: {
            page: page + 1,
          },
        };
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message || "No User Found!");
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Users Error", error.message);
      return {
        options: [],
        hasMore: false,
      };
    }
  };
  const handleUserChange = (selectedOption) => {
    setUser(selectedOption);
  };

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ];
  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };
  const verifyShortStoryStepOne = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!title) {
        toast.error(`Title is Required for Short Story`, {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (!status) {
        toast.error("Please select Status for a Short Story", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (!user) {
        toast.error("Please select User for a Short Story", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (!category) {
        toast.error("Please select Category for a Short Story", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (!subCategory) {
        toast.error("Please select Sub-Category for a Short Story", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      // Strip HTML tags and check if the remaining text is empty or contains only whitespace characters
      const strippedHtml = description.replace(/<[^>]+>/g, "");
      if (!strippedHtml.trim() || !description) {
        toast.error(`Please Write the Article for a Short Story.`, {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      } else {
        const wordCount = getWordCount(description);
        const categoryWordLimit = subCategory?.wordLimit;
        if (categoryWordLimit) {
          if (wordCount > categoryWordLimit) {
            toast.error(
              `Article word count exceeds category's word limit of ${categoryWordLimit}`,
              {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
              }
            );
            return false;
          }
        }
      }
      setIsReady(false);
      navigate(`/addPost/generate-card`, {
        state: {
          product,
          article: description,
          category,
          subCategory,
          user,
          title: title,
          hashtags,
          status: typeof status === "object" ? status.value : status,
        },
      });
    } catch (error) {
      console.log("Create Short Story Step One error", error.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchChallenges = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/challenge/get-all-challenges`,
        token,
        {
          page: page,
          search: search,
          status: "active",
          productId: product?._id,
          categoryId: category?._id,
          subCategoryId: subCategory?._id,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, challenges } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: challenges,
            hasMore: false,
          };
        } else {
          return {
            options: challenges,
            hasMore: challenges?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Challenges Error", error);
    }
  };
  const fetchCategories = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/category/get-all-categories`,
        token,
        {
          page: page,
          search: search,
          status: "active",
          productType: product?._id,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, categories } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: categories,
            hasMore: false,
          };
        } else {
          return {
            options: categories,
            hasMore: categories?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Sub Categories Error", error);
    }
  };
  const fetchSubCategories = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/category/get-categories-from-master`,
        token,
        {
          page: page,
          search: search,
          status: "active",
          category: category?._id,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, categories } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: categories,
            hasMore: false,
          };
        } else {
          return {
            options: categories,
            hasMore: categories?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Sub Categories Error", error);
    }
  };
  const getLabelByValue = (options, value) => {
    const option = options?.find((option) => option.value === value);
    return option?.label;
  };

  const handleCategoryChange = (selectedOption) => {
    setCategory(selectedOption);
    setSubCategory();
  };
  const handleSubCategoryChange = (selectedOption) => {
    setSubCategory(selectedOption);
  };
  const hangleChallengeChange = (selectedOption) => {
    setChallenge(selectedOption);
  };

  return (
    <section id="add_user_form_section" className="py-5 upload-video">
      <Container>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Row className="pt-3">
            <Col xl={4} sm={12}>
              <Form.Label>
                Title <span>*</span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder={`Title`}
                  className="show "
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col xl={4} sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Status</Form.Label>
                <ReactSelect
                  placeholder="Select Status"
                  onChange={statusChange}
                  options={statusOptions}
                  value={status ? status : ""}
                />
              </Form.Group>
            </Col>
            <Col xl={4} sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>User</Form.Label>
                <AsyncPaginate
                  value={user ? user : ""}
                  loadOptions={fetchUsers}
                  getOptionLabel={(e) =>
                    `${e.firstName} ${e.lastName} (${e.email})`
                  }
                  getOptionValue={(e) => e._id}
                  onChange={handleUserChange}
                  placeholder="Select User"
                  isClearable
                  loadingMessage={() => "Loading..."}
                  noOptionsMessage={() => "No User Found"}
                  additional={{
                    page: 1,
                  }}
                />
              </Form.Group>
            </Col>
            {product && (
              <Col lg={6} md={6} xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Category</Form.Label>
                  <AsyncPaginate
                    value={category ? category : ""}
                    loadOptions={fetchCategories}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option._id}
                    onChange={handleCategoryChange}
                    placeholder="Select Category"
                    isClearable
                    required
                    loadingMessage={() => "Loading..."}
                    noOptionsMessage={() => "No Category Found"}
                    additional={{
                      page: 1,
                    }}
                  />
                </Form.Group>
              </Col>
            )}
            {category && (
              <Col lg={6} md={6} xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Sub Category</Form.Label>
                  <AsyncPaginate
                    value={subCategory ? subCategory : ""}
                    loadOptions={fetchSubCategories}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option._id}
                    onChange={handleSubCategoryChange}
                    placeholder="Select Sub Category"
                    isClearable
                    required
                    loadingMessage={() => "Loading..."}
                    noOptionsMessage={() => "No Sub Category Found"}
                    additional={{
                      page: 1,
                    }}
                  />
                </Form.Group>
              </Col>
            )}
            {/* <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Link to Challenge"
                  checked={linkToChallenge}
                  onChange={() => {
                    setLinkToChallenge((prevState) => !prevState);
                  }}
                />
              </Form.Group>
              {linkToChallenge &&
                (category && subCategory ? (
                  <Form.Group className="mb-3">
                    <Form.Label>Challenge</Form.Label>
                    <AsyncPaginate
                      value={challenge ? challenge : ""}
                      loadOptions={fetchChallenges}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      onChange={hangleChallengeChange}
                      placeholder="Select Challenge"
                      isClearable
                      required
                      loadingMessage={() => "Loading..."}
                      noOptionsMessage={() => "No Challenge Found"}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Form.Group>
                ) : (
                  <p>
                    Please select the Category and Sub Category first.
                  </p>
                ))}
            </Col> */}

            <Col lg={12} className="">
              <Form.Group className="mb-3">
                <Form.Label>Article</Form.Label>
                {/* {state ? (
                  isReady && (
                    <PostEditor
                      editorState={description}
                      onChangeEditor={(editor, html, hashtags) => {
                        setDescription(editor);
                        setDescriptionHTML(html);
                        setHashtags(hashtags);
                      }}
                      isReady={isReady}
                      // shortStories={shortStories}
                      // setShortStories={setShortStories}
                    />
                  )
                ) : (
                  <PostEditor
                    editorState={description}
                    onChangeEditor={(editor, html, hashtags) => {
                      setDescription(editor);
                      setDescriptionHTML(html);
                      setHashtags(hashtags);
                    }}
                    // shortStories={shortStories}
                    // setShortStories={setShortStories}
                  />
                )} */}

                {state ? (
                  isReady && (
                    <EditorWithMentionsAndHashtags
                      initialContent={description}
                      placeholder={`Description of your Short Story. A few sentences is usually recommended.`}
                      onContentChange={handleContentChange}
                      isReady={isReady}
                    />
                  )
                ) : (
                  <EditorWithMentionsAndHashtags
                    initialContent={description}
                    placeholder={`Description of your Short Story. A few sentences is usually recommended.`}
                    onContentChange={handleContentChange}
                    isReady={isReady}
                  />
                )}
                {/* <EditorWithMentionsAndHashtags
                  initialContent={description}
                  placeholder={`Description of your Short Story. A few sentences is usually recommended.`}
                  onContentChange={handleContentChange}
                /> */}
              </Form.Group>
            </Col>
            <Col lg={12} className="text-center">
              <Button
                type="button"
                className="btn-default"
                onClick={verifyShortStoryStepOne}
              >
                Next
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};

export default AddShortStoryPost;
