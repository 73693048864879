import {
  faFileArrowDown,
  faPaperPlane,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../../../helper/api";
import Layout from "../../../layouts/admin/Layout";

import { CSVLink } from "react-csv";
import { DateRangePicker } from "react-date-range";
import ReactSelect from "react-select";
import Loader from "../../../components/Loader";
import {
  cronScheduleToDate,
  getItemFromLocalStorage,
} from "../../../helper/helper";
import { toast } from "react-toastify";
import { AsyncPaginate } from "react-select-async-paginate";

const EmailNotifications = () => {
  const navigate = useNavigate();
  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [topicOfInterests, setTopicOfInterests] = useState();
  const [topicOfInterest, setTopicOfInterest] = useState();
  const [users, setUsers] = useState();
  const [user, setUser] = useState();

  const getAllEmailNotifications = async (
    page = 1,
    limit = 10,
    search = "",
    topicOfInterest = "",
    startDate = "",
    endDate = "",
    users = ""
  ) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/emailNotification/email-list`,
        token,
        {
          page,
          limit,
          search,
          topicOfInterests: topicOfInterest,
          startDate,
          endDate,
          recipients: users,
        }
      );
      setTableHead([
        {
          name: "Subject",
          sortable: true,
          selector: (row) => row.subject,
        },
        // {
        //   name: "Product Types",
        //   selector: (row) => (row.productType ? row.productType.length : 0),
        // },
        // {
        //   name: "Categories",
        //   selector: (row) => (row.categories ? row.categories.length : 0),
        // },
        // {
        //   name: "Sub Categories",
        //   selector: (row) => (row.subCategories ? row.subCategories.length : 0),
        // },
        {
          name: "Topic of Intrests",
          selector: (row) =>
            row.topicOfInterests ? row.topicOfInterests.length : 0,
        },
        {
          name: "Users",
          selector: (row) => (row.recipients ? row.recipients.length : 0),
        },
        {
          name: "Schedule",
          grow: 1,
          selector: (row) =>
            moment(cronScheduleToDate(row.schedule)).format("D-M-Y h:mm A"),
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              {/* {!row.executed && (
                <Button
                  className="edit_btn me-1"
                  size="sm"
                  variant="primary"
                  onClick={() =>
                    editEmailNotification(row.slug, row, index, column, id)
                  }
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
              )} */}
              {row.executed && (
                <Button
                  variant="success"
                  onClick={() => {
                    resendEmail(row._id);
                  }}
                  title="Re-Send"
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </Button>
              )}
            </>
          ),
        },
      ]);
      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201 ||
        response?.result?.status === 201
      ) {
        setTableData(response?.result?.data?.emails);
        setTotalRows(response?.result?.data?.totalEmails);
      } else {
        setTableData([]);
        setTotalRows(0);
      }
    } catch (error) {
      console.log("Get All Email Notifications Error", error);
    } finally {
      setPending(false);
    }
  };
  const resendEmail = async (emailNotificationId) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequest(
        `/api/secure/emailNotification/resendEmailNotification`,
        token,
        {
          emailNotificationId,
        }
      );
      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        toast.success("Email Notification Sent", {
          position: "top-center", theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        getAllEmailNotifications(currentPage, perPage);
      }
    } catch (error) {
      console.log("Resend Email Notification Error", error);
    } finally {
      setPending(false);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllEmailNotifications(
      page,
      perPage,
      searchItem,
      topicOfInterest ? topicOfInterest?.value : "",
      startDate,
      endDate,
      user ? user?._id : ""
    );
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(1);
    getAllEmailNotifications(
      1,
      newPerPage,
      searchItem,
      topicOfInterest ? topicOfInterest?.value : "",
      startDate,
      endDate,
      user ? user?._id : ""
    );
  };

  const handleSearchEmails = (e) => {
    const searchValue = e.target.value;
    setSearchItem(searchValue);
    setCurrentPage(1);
    getAllEmailNotifications(
      1,
      perPage,
      searchValue,
      topicOfInterest ? topicOfInterest?.value : "",
      startDate,
      endDate,
      user ? user?._id : ""
    );
  };

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;

    getAllEmailNotifications(
      1,
      perPage,
      searchItem,
      topicOfInterest ? topicOfInterest?.value : "",
      startDate,
      endDate,
      user ? user?._id : ""
    );
  };

  const handleTopicOfIntrest = (selectedOption) => {
    setTopicOfInterest(selectedOption);
    getAllEmailNotifications(
      1,
      perPage,
      searchItem,
      selectedOption?.value,
      startDate,
      endDate,
      user ? user?._id : ""
    );
  };

  const handleUserChange = (selectedOption) => {
    setUser(selectedOption);
    getAllEmailNotifications(
      1,
      perPage,
      searchItem,
      topicOfInterest ? topicOfInterest?.value : "",
      startDate,
      endDate,
      selectedOption?._id
    );
  };

  const getAllTopicOfIntrest = async (query) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/topicOfInterest/get-all-topic-of-interest`,
        token
      );

      const mappedOptions = response?.result?.data?.topicOfInterests?.map(
        (option) => ({
          value: option._id,
          label: option.name,
          // label: `${option.name} (${option.parentCategoryId?.name}) {${option.parentCategoryId?.masterProductId?.name}}`,
        })
      );

      setTopicOfInterests(mappedOptions);
    } catch (error) {
      console.log("Get All Topic Of Interest Error", error);
    } finally {
      setPending(false);
    }
  };

  const fetchUsers = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(`/api/secure/user/get-all`, token, {
        page: page,
        search: search,
      });

      const currentPage = response?.result?.data?.currentPage;
      const totalPages = response?.result?.data?.totalPages;

      if (currentPage === totalPages) {
        return {
          options: response?.result?.data?.users,
          hasMore: false,
        };
      } else {
        return {
          options: response?.result?.data?.users,
          hasMore: response?.result?.data?.users?.length >= 1,
          additional: {
            page: page + 1,
          },
        };
      }
    } catch (error) {
      console.log("Get All Users Error", error);
    }
  };

  const csvData = useMemo(() => {
    return tableData?.map((user) => ({
      id: user._id,
      subject: user.name,
      slug: user.slug,
      parentCategory: user.parentCategoryId?.name,
      productType: user.parentCategoryId?.masterProductId?.name,
      adminCategory: user.adminCategory ? "Yes" : "No",
      is_sent: user.executed ? "Yes" : "No",
      created_at: moment(user.createdAt).format("D-M-Y"),
    }));
  }, [tableData]);

  const selectionRange = useMemo(() => {
    return {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    };
  }, [startDate, endDate]);

  const editEmailNotification = async (emailNotificationId) => {
    navigate(`/EditEmailNotification`, {
      state: { emailNotificationId },
    });
  };

  const renderExpandedRow = useCallback((row) => {
    return (
      <Container fluid className="py-3">
        <p>
          <b>RECIPIENTS:</b>
        </p>
        {row.data.recipients.map((recipient) => (
          <span>{recipient}, </span>
        ))}
        <p>
          <b>Message:</b>
        </p>
        <div
          dangerouslySetInnerHTML={{
            __html: row.data.message,
          }}
        />
      </Container>
    );
  }, []);
  useEffect(() => {
    getAllTopicOfIntrest();
    getAllEmailNotifications(currentPage, perPage);
  }, [currentPage, perPage]);

  return (
    <Layout>
      <section id="Posts_list" className="pt-5">
        <Container>
          <Row className="mb-3">
            <Col lg={3} md={6} xs={12} className="mt-3">
              <FormControl
                type="text"
                placeholder="Search Emails"
                className="me-2"
                value={searchItem}
                onChange={handleSearchEmails}
              />
            </Col>
            <Col lg={3} md={6} xs={12} className="mt-3">
              <Form.Group>
                <ReactSelect
                  placeholder="Topic Of Intrest"
                  onChange={handleTopicOfIntrest}
                  options={topicOfInterests}
                  value={topicOfInterest ? topicOfInterest : ""}
                  required
                  isClearable
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} xs={12} className="mt-3">
              <Form.Group>
                <AsyncPaginate
                  value={user ? user : ""}
                  loadOptions={fetchUsers}
                  getOptionLabel={(e) =>
                    `${e.firstName} ${e.lastName} (${e.email})`
                  }
                  getOptionValue={(e) => e._id}
                  onChange={handleUserChange}
                  placeholder="Select User"
                  isClearable
                  loadingMessage={() => "Loading..."}
                  noOptionsMessage={() => "No User Found"}
                  additional={{
                    page: 1,
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} xs={12} className="mt-3">
              <Dropdown autoClose={"outside"} className="w-100">
                <Dropdown.Toggle className="w-100">
                  {startDate || endDate
                    ? `${startDate ? moment(startDate).format("D-M-Y") : ""} ${
                        endDate ? `- ${moment(endDate).format("D-M-Y")}` : ""
                      }`
                    : "Select Date Range"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setStartDate();
                        setEndDate();
                        getAllEmailNotifications();
                      }}
                    >
                      Clear
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {tableData && (
              <Col lg={1} md={6} xs={12} className="mt-3">
                <CSVLink
                  data={csvData}
                  filename={"emailNotifications.csv"}
                  className="btn btn-success"
                >
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </CSVLink>
              </Col>
            )}
          </Row>
          <br />
          <br />
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <DataTable
              columns={tableHead}
              data={tableData}
              style={{
                borderRadius: "10px",
              }}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={pending}
              expandableRows
              expandableRowsComponent={renderExpandedRow}
            />
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default EmailNotifications;
